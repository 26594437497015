import React, { Component, Fragment } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { TwitterPicker } from "react-color";
import { Formik } from "formik";
import * as Yup from "yup";

//My Components
import AddOn from "../utilities/AddOn";
import HelperToolTip from "../utilities/HelperToolTip";
import Upload from "../Upload";
import UploadBanner from "../UploadBanner";
import UserContext from "../../contexts/UserContext";
import TemplateContext from "../../contexts/TemplateContext";
import { LanguageContext } from "../../contexts/LanguageProvider";

//const swatchHoverHandler = (color, event) => {};

class AddOnTab extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.handleErrorChange = this.handleErrorChange.bind(this);
        this.handleSocialErrorChange = this.handleSocialErrorChange.bind(this);
        this.socialMediaClick = this.socialMediaClick.bind(this);
        this.state = {
            facebookToggle: true,
            twitterToggle: true,
            instagramToggle: true,
            linkedinToggle: true,
            youtubeToggle: true,
            pinterestToggle: true,
            primaryColor: "#005baa",
            secondaryColor: "#FFFFFF",
            headersColor: "#005baa",
            textColor: "#1f1f1f",
            linksColor: "#205CCA",
            customBannerToggle: true,
            initialize: false,
            applicationInitialize: true,
            myMortgageToolboxInitialize: true,
            customBannerInitialize: true,
            confidentialityWarningInitialize: true,
        };
    }

    primaryHandleChangeComplete = (color) => {
        let { template, updateTemplate } = this.context;
        this.setState({ primaryColor: color.hex });
        template.features.colorScheme.primary = this.state.primaryColor;
        updateTemplate(template);
    };

    secondaryHandleChangeComplete = (color) => {
        let { template, updateTemplate } = this.context;
        this.setState({ secondaryColor: color.hex });
        template.features.colorScheme.secondary = this.state.secondaryColor;
        updateTemplate(template);
    };

    headersHandleChangeComplete = (color) => {
        let { template, updateTemplate } = this.context;
        this.setState({ headersColor: color.hex });
        template.features.colorScheme.headers = this.state.headersColor;
        updateTemplate(template);
    };

    textHandleChangeComplete = (color) => {
        let { template, updateTemplate } = this.context;
        this.setState({ textColor: color.hex });
        template.features.colorScheme.text = this.state.textColor;
        updateTemplate(template);
    };

    linksHandleChangeComplete = (color) => {
        let { template, updateTemplate } = this.context;
        this.setState({ linksColor: color.hex });
        template.features.colorScheme.links = this.state.linksColor;
        updateTemplate(template);
    };

    handleSocialChange(e) {
        let { template, updateTemplate } = this.context;
        template.features.socialMediaUrls[e.target.name] = e.target.value;
        //this.setState({ initialize: false });
        //template.features.socialMediaUrls.facebook = values.facebook;
        updateTemplate(template);
    }

    socialMediaClick(e) {
        //console.log(e.target.id);
        let { template, updateTemplate } = this.context;
        let targetId = e.target.id;
        let targetIdToggle = targetId + "Toggle";
        this.setState((state) => ({
            [targetIdToggle]: !state[targetIdToggle],
            initialize: true,
        }));
        if (this.state[targetIdToggle] === false) {
            this.setState({ initialize: true });
            template.features.socialMediaUrls[targetId] = null;
            Object.keys(template.features.socialMediaUrls).forEach(
                (k) => !template.features.socialMediaUrls[k] && template.features.socialMediaUrls[k] !== undefined && delete template.features.socialMediaUrls[k]
            );
            console.log(template.features.socialMediaUrls[targetId]);
        }
        updateTemplate(template);
    }

    handleFeatureChange(e, user) {
        let { template, updateTemplate } = this.context;
        //template.features[e.target.name] = e.target.value;
        if (user.type === "franchise-user") {
            if (template.member[e.target.name] === template.member.applicationUrl || template.member[e.target.name] === template.member.mortgageToolboxUrl) {
                template.member[e.target.name] = e.target.value;
            } else {
                template.features[e.target.name] = e.target.value;
            }
        } else {
            template.features[e.target.name] = e.target.value;
        }
        updateTemplate(template);
    }

    handleSocialErrorChange(e) {
        let { template, updateTemplate } = this.context;
        template.features.socialMediaUrls[e.target.name] = "";
        this.setState({ initialize: false });
        updateTemplate(template);
    }

    handleButtonUrlsErrorChange(e, user) {
        let { template, updateTemplate } = this.context;
        if (user.type === "franchise-user") {
            template.member[e.target.name] = "";
            if (template.member[e.target.name] === template.member.applicationUrl) {
                this.setState({ applicationInitialize: false });
            } else {
                this.setState({ myMortgageToolboxInitialize: false });
            }
        } else {
            template.features[e.target.name] = "";
            if (template.features[e.target.name] === template.features.applicationUrl) {
                this.setState({ applicationInitialize: false });
            } else {
                this.setState({ myMortgageToolboxInitialize: false });
            }
        }
        updateTemplate(template);
    }

    handleErrorChange(e) {
        let { template, updateTemplate } = this.context;
        if (template.features[e.target.name] === template.features.confidentialityWarning) {
            this.setState({ confidentialityWarningInitialize: false });
        }
        template.features[e.target.name] = "";
        updateTemplate(template);
    }

    render() {
        let { template } = this.context;
        const { intl } = this.props;
        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <LanguageContext.Consumer>
                        {(language) => (
                            <Fragment>
                                <Row className="sub-header">
                                    <Col>
                                        <FormattedMessage id="addOnTab.installedSubHeader" defaultMessage="Available Add-ons" />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        {user.type === "franchise-user" && (
                                            <Fragment>
                                                <AddOn
                                                    id="colorScheme"
                                                    translateId="addOnTab.colorSchemeAddOn"
                                                    translateMessage="Colour Scheme"
                                                    status={
                                                        template.features.colorScheme.primary === "" &&
                                                        template.features.colorScheme.secondary === "" &&
                                                        template.features.colorScheme.headers === "" &&
                                                        template.features.colorScheme.text === "" &&
                                                        template.features.colorScheme.links === ""
                                                            ? "deactive"
                                                            : "active"
                                                    }
                                                >
                                                    <label className="mb-2">
                                                        <FormattedMessage id="addOnTab.primaryLabel" defaultMessage="Primary" />
                                                    </label>
                                                    <TwitterPicker
                                                        color={template.features.colorScheme.primary ? this.state.primaryColor : ""}
                                                        onChangeComplete={this.primaryHandleChangeComplete}
                                                        colors={["#005BAA", "#24C4F0", "#FF6900", "#FCB900", "#7BDCB5", "#00D084", "#ABB8C3", "#EB144C", "#F78DA7", "#9900EF"]}
                                                    />

                                                    <label className="mt-3 mb-2">
                                                        <FormattedMessage id="addOnTab.secondaryLabel" defaultMessage="Secondary" />
                                                    </label>
                                                    <TwitterPicker
                                                        color={template.features.colorScheme.secondary ? this.state.secondaryColor : ""}
                                                        onChangeComplete={this.secondaryHandleChangeComplete}
                                                        colors={["#FFFFFF", "#dee7eb", "#dddddd", "#EB144C", "#00D084", "#7BDCB5", "#FCB900", "#ABB8C3", "#F78DA7", "#9900EF"]}
                                                    />

                                                    <label className="mt-3 mb-2">
                                                        <FormattedMessage id="addOnTab.headersLabel" defaultMessage="Headers" />
                                                    </label>
                                                    <TwitterPicker
                                                        color={template.features.colorScheme.headers ? this.state.headersColor : ""}
                                                        onChangeComplete={this.headersHandleChangeComplete}
                                                        colors={["#005BAA", "#24C4F0", "#FF6900", "#FCB900", "#7BDCB5", "#00D084", "#ABB8C3", "#EB144C", "#F78DA7", "#9900EF"]}
                                                    />

                                                    <label className="mt-3 mb-2">
                                                        <FormattedMessage id="addOnTab.textLabel" defaultMessage="Text" />
                                                    </label>
                                                    <TwitterPicker
                                                        color={template.features.colorScheme.text ? this.state.textColor : ""}
                                                        onChangeComplete={this.textHandleChangeComplete}
                                                        colors={["#1f1f1f", "#262626", "#333333", "#404040", "#4D4D4D", "#666666", "#5F6364", "#70767A", "#878E94", "#9900EF"]}
                                                    />

                                                    <label className="mt-3 mb-2">
                                                        <FormattedMessage id="addOnTab.linksLabel" defaultMessage="Links" />
                                                    </label>
                                                    <TwitterPicker
                                                        color={template.features.colorScheme.links ? this.state.linksColor : ""}
                                                        onChangeComplete={this.linksHandleChangeComplete}
                                                        colors={["#205CCA", "#24C4F0", "#FF6900", "#FCB900", "#7BDCB5", "#00D084", "#ABB8C3", "#EB144C", "#F78DA7", "#9900EF"]}
                                                    />
                                                </AddOn>
                                            </Fragment>
                                        )}

                                        {(user.type === "franchise-user" || user.type === "broker-user") && (
                                            <Fragment>
                                                <AddOn
                                                    id="socialMediaUrls"
                                                    translateId="addOnTab.socialMediaAddOn"
                                                    translateMessage="Social Media"
                                                    status={
                                                        (template.features.socialMediaUrls.facebook === "" &&
                                                            template.features.socialMediaUrls.twitter === "" &&
                                                            template.features.socialMediaUrls.instagram === "" &&
                                                            template.features.socialMediaUrls.linkedin === "" &&
                                                            template.features.socialMediaUrls.youtube === "" &&
                                                            template.features.socialMediaUrls.pinterest === "") ||
                                                        Object.keys(template.features.socialMediaUrls).length === 0
                                                            ? "deactive"
                                                            : "active"
                                                    }
                                                >
                                                    <Row id="social-media" className="mt-2 mb-2">
                                                        <Col className="text-center">
                                                            <i
                                                                id="facebook"
                                                                className={this.state.facebookToggle ? "fab fa-facebook-square" : "fab fa-facebook-square active"}
                                                                onClick={this.socialMediaClick.bind(this)}
                                                            />
                                                        </Col>

                                                        <Col className="text-center">
                                                            <i
                                                                id="twitter"
                                                                className={this.state.twitterToggle ? "fab fa-twitter-square" : "fab fa-twitter-square active"}
                                                                onClick={this.socialMediaClick.bind(this)}
                                                            />
                                                        </Col>

                                                        <Col className="text-center">
                                                            <i
                                                                id="instagram"
                                                                className={this.state.instagramToggle ? "fab fa-instagram-square" : "fab fa-instagram-square active"}
                                                                onClick={this.socialMediaClick.bind(this)}
                                                            />
                                                        </Col>

                                                        <Col className="text-center">
                                                            <i
                                                                id="linkedin"
                                                                className={this.state.linkedinToggle ? "fab fa-linkedin" : "fab fa-linkedin active"}
                                                                onClick={this.socialMediaClick.bind(this)}
                                                            />
                                                        </Col>

                                                        <Col className="text-center">
                                                            <i
                                                                id="youtube"
                                                                className={this.state.youtubeToggle ? "fab fa-youtube-square" : "fab fa-youtube-square active"}
                                                                onClick={this.socialMediaClick.bind(this)}
                                                            />
                                                        </Col>

                                                        <Col className="text-center">
                                                            <i
                                                                id="pinterest"
                                                                className={this.state.pinterestToggle ? "fab fa-pinterest-square" : "fab fa-pinterest-square active"}
                                                                onClick={this.socialMediaClick.bind(this)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Formik
                                                        initialValues={{
                                                            facebook: template.features.socialMediaUrls.facebook ? template.features.socialMediaUrls.facebook : "",
                                                            twitter: template.features.socialMediaUrls.twitter ? template.features.socialMediaUrls.twitter : "",
                                                            instagram: template.features.socialMediaUrls.instagram ? template.features.socialMediaUrls.instagram : "",
                                                            linkedin: template.features.socialMediaUrls.linkedin ? template.features.socialMediaUrls.linkedin : "",
                                                            youtube: template.features.socialMediaUrls.youtube ? template.features.socialMediaUrls.youtube : "",
                                                            pinterest: template.features.socialMediaUrls.pinterest ? template.features.socialMediaUrls.pinterest : "",
                                                        }}
                                                        enableReinitialize={this.state.initialize === true ? true : false}
                                                        validationSchema={Yup.object().shape({
                                                            facebook: Yup.string()
                                                                .matches(
                                                                    /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w]*\/)*([\w]+)(?:\/)?/,
                                                                    intl.formatMessage({
                                                                        id: "addOnTab.facebookValidation",
                                                                        defaultMessage: "Invalid Facebook url",
                                                                    })
                                                                )
                                                                .notRequired(),

                                                            twitter: Yup.string()
                                                                .matches(
                                                                    /https?:\/\/(?:www\.)?(?:twitter\.com|x\.com)\/([a-zA-Z0-9_]+)/,
                                                                    intl.formatMessage({
                                                                        id: "addOnTab.twitterValidation",
                                                                        defaultMessage: "Invalid Twitter url",
                                                                    })
                                                                )
                                                                .notRequired(),

                                                            instagram: Yup.string()
                                                                .matches(
                                                                    /http(?:s)?:\/\/(?:www\.)?instagram\.com\/([a-zA-Z0-9_]+)/,
                                                                    intl.formatMessage({
                                                                        id: "addOnTab.instagramValidation",
                                                                        defaultMessage: "Invalid Instagram url",
                                                                    })
                                                                )
                                                                .notRequired(),

                                                            linkedin: Yup.string()
                                                                .matches(
                                                                    /http(?:s)?:\/\/(?:www\.)?(?:[a-z]{2}\.)?linkedin\.com\/([a-zA-Z0-9_]+)/,
                                                                    intl.formatMessage({
                                                                        id: "addOnTab.linkedinValidation",
                                                                        defaultMessage: "Invalid LinkedIn url",
                                                                    })
                                                                )
                                                                .notRequired(),

                                                            youtube: Yup.string()
                                                                .matches(
                                                                    /http(?:s)?:\/\/(?:www\.)?youtube\.com\/(?:@)?([a-zA-Z0-9_]+)/,
                                                                    intl.formatMessage({
                                                                        id: "addOnTab.youtubeValidation",
                                                                        defaultMessage: "Invalid YouTube url",
                                                                    })
                                                                )
                                                                .notRequired(),

                                                            pinterest: Yup.string()
                                                                .matches(
                                                                    /http(?:s)?:\/\/(?:www\.)?pinterest\.ca\/([a-zA-Z0-9_]+)/,
                                                                    intl.formatMessage({
                                                                        id: "addOnTab.pinterestValidation",
                                                                        defaultMessage: "Invalid Pinterest url",
                                                                    })
                                                                )
                                                                .notRequired(),
                                                        })}
                                                    >
                                                        {(props) => {
                                                            const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                                            return (
                                                                <form className="mb-2" onSubmit={handleSubmit}>
                                                                    <div className={this.state.facebookToggle ? "d-none" : "d-block"}>
                                                                        <label className="mt-2" htmlFor="facebook-link">
                                                                            Facebook URL
                                                                        </label>
                                                                        <input
                                                                            id="facebook-link"
                                                                            type="text"
                                                                            name="facebook"
                                                                            value={values.facebook}
                                                                            disabled={this.state.facebookToggle ? "disabled" : null}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                //submitForm();
                                                                                this.handleSocialChange(e);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);

                                                                                if (errors.facebook) {
                                                                                    this.handleSocialErrorChange(e);
                                                                                }
                                                                            }}
                                                                            className={errors.facebook && touched.facebook ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.facebook && touched.facebook && <div className="input-feedback">{errors.facebook}</div>}
                                                                    </div>

                                                                    <div className={this.state.twitterToggle ? "d-none" : "d-block"}>
                                                                        <label className="mt-2" htmlFor="twitter-link">
                                                                            Twitter URL
                                                                        </label>
                                                                        <input
                                                                            id="twitter-link"
                                                                            type="text"
                                                                            name="twitter"
                                                                            value={values.twitter}
                                                                            disabled={this.state.twitterToggle ? "disabled" : null}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                this.handleSocialChange(e, values);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);

                                                                                if (errors.twitter) {
                                                                                    this.handleSocialErrorChange(e);
                                                                                }
                                                                            }}
                                                                            className={errors.twitter && touched.twitter ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.twitter && touched.twitter && <div className="input-feedback">{errors.twitter}</div>}
                                                                    </div>

                                                                    <div className={this.state.instagramToggle ? "d-none" : "d-block"}>
                                                                        <label className="mt-2" htmlFor="instagram-link">
                                                                            Instagram URL
                                                                        </label>
                                                                        <input
                                                                            id="instagram-link"
                                                                            type="text"
                                                                            name="instagram"
                                                                            value={values.instagram}
                                                                            disabled={this.state.instagramToggle ? "disabled" : null}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                this.handleSocialChange(e, values);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);

                                                                                if (errors.instagram) {
                                                                                    this.handleSocialErrorChange(e);
                                                                                }
                                                                            }}
                                                                            className={errors.instagram && touched.instagram ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.instagram && touched.instagram && <div className="input-feedback">{errors.instagram}</div>}
                                                                    </div>

                                                                    <div className={this.state.linkedinToggle ? "d-none" : "d-block"}>
                                                                        <label className="mt-2" htmlFor="linkedin-link">
                                                                            LinkedIn URL
                                                                        </label>
                                                                        <input
                                                                            id="linkedin-link"
                                                                            type="text"
                                                                            name="linkedin"
                                                                            maxLength="50"
                                                                            value={values.linkedin}
                                                                            disabled={this.state.linkedinToggle ? "disabled" : null}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                this.handleSocialChange(e, values);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e, values);

                                                                                if (errors.linkedin) {
                                                                                    this.handleSocialErrorChange(e);
                                                                                }
                                                                            }}
                                                                            className={errors.linkedin && touched.linkedin ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.linkedin && touched.linkedin && <div className="input-feedback">{errors.linkedin}</div>}
                                                                    </div>

                                                                    <div className={this.state.youtubeToggle ? "d-none" : "d-block"}>
                                                                        <label className="mt-2" htmlFor="youtube-link">
                                                                            YouTube URL
                                                                        </label>
                                                                        <input
                                                                            id="youtube-link"
                                                                            type="text"
                                                                            name="youtube"
                                                                            maxLength="50"
                                                                            value={values.youtube}
                                                                            disabled={this.state.youtubeToggle ? "disabled" : null}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                this.handleSocialChange(e, values);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);

                                                                                if (errors.youtube) {
                                                                                    this.handleSocialErrorChange(e);
                                                                                }
                                                                            }}
                                                                            className={errors.youtube && touched.youtube ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.youtube && touched.youtube && <div className="input-feedback">{errors.youtube}</div>}
                                                                    </div>

                                                                    <div className={this.state.pinterestToggle ? "d-none" : "d-block"}>
                                                                        <label className="mt-2" htmlFor="pinterest-link">
                                                                            Pinterest URL
                                                                        </label>
                                                                        <input
                                                                            id="pinterest-link"
                                                                            type="text"
                                                                            maxLength="50"
                                                                            name="pinterest"
                                                                            disabled={this.state.pinterestToggle ? "disabled" : null}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                this.handleSocialChange(e, values);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);

                                                                                if (errors.pinterest) {
                                                                                    this.handleSocialErrorChange(e);
                                                                                }
                                                                            }}
                                                                            className={errors.pinterest && touched.pinterest ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.pinterest && touched.pinterest && <div className="input-feedback">{errors.pinterest}</div>}
                                                                    </div>
                                                                </form>
                                                            );
                                                        }}
                                                    </Formik>
                                                </AddOn>

                                                {user.type === "broker-user" ? (
                                                    <AddOn
                                                        id="applicationUrl"
                                                        translateId="addOnTab.applicationAddOn"
                                                        translateMessage="Application"
                                                        status={template.features.applicationUrl === undefined || template.features.applicationUrl === "" ? "deactive" : "active"}
                                                    >
                                                        <Formik
                                                            initialValues={{ applicationUrl: template.features.applicationUrl ? template.features.applicationUrl : "" }}
                                                            enableReinitialize={this.state.applicationInitialize === true ? true : false}
                                                            validationSchema={Yup.object().shape({
                                                                applicationUrl: Yup.string()
                                                                    .matches(
                                                                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\//,
                                                                        intl.formatMessage({
                                                                            id: "addOnTab.applicationValidation",
                                                                            defaultMessage: "Invalid application url",
                                                                        })
                                                                    )
                                                                    .notRequired(),
                                                            })}
                                                        >
                                                            {(props) => {
                                                                const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                                                return (
                                                                    <form className="mb-2" onSubmit={handleSubmit}>
                                                                        <label className="mt-2" htmlFor="broker-application-link">
                                                                            <FormattedMessage id="addOnTab.applicationLabel" defaultMessage="Application URL" />
                                                                            <HelperToolTip translationId="addOnTab.applicationLabel" defaultMessage="Application URL">
                                                                                <FormattedMessage
                                                                                    id="helperToolTip.addOnTabApplication"
                                                                                    defaultMessage="Application URL is represented by the 'Apply Now' button. All application URLs should be: https://secure.dominionlending.ca/?app=[YOUR ID]&lang=en"
                                                                                />
                                                                            </HelperToolTip>
                                                                        </label>
                                                                        <input
                                                                            id="broker-application-link"
                                                                            type="text"
                                                                            name="applicationUrl"
                                                                            value={values.applicationUrl}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                this.handleFeatureChange(e, user);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);

                                                                                if (errors.applicationUrl) {
                                                                                    this.handleButtonUrlsErrorChange(e, user);
                                                                                }
                                                                            }}
                                                                            className={errors.applicationUrl && touched.applicationUrl ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.applicationUrl && touched.applicationUrl && <div className="input-feedback">{errors.applicationUrl}</div>}
                                                                    </form>
                                                                );
                                                            }}
                                                        </Formik>
                                                    </AddOn>
                                                ) : (
                                                    <AddOn
                                                        id="applicationUrl"
                                                        translateId="addOnTab.applicationAddOn"
                                                        translateMessage="Application"
                                                        status={template.member.applicationUrl === undefined || template.member.applicationUrl === "" ? "deactive" : "active"}
                                                    >
                                                        <Formik
                                                            initialValues={{ applicationUrl: template.member.applicationUrl }}
                                                            enableReinitialize={this.state.applicationInitialize === true ? true : false}
                                                            validationSchema={Yup.object().shape({
                                                                applicationUrl: Yup.string()
                                                                    .matches(
                                                                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\//,
                                                                        intl.formatMessage({
                                                                            id: "addOnTab.applicationValidation",
                                                                            defaultMessage: "Invalid application url",
                                                                        })
                                                                    )
                                                                    .notRequired(),
                                                            })}
                                                        >
                                                            {(props) => {
                                                                const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                                                return (
                                                                    <form className="mb-2" onSubmit={handleSubmit}>
                                                                        <label className="mt-2" htmlFor="broker-application-link">
                                                                            <FormattedMessage id="addOnTab.applicationLabel" defaultMessage="Application URL" />
                                                                            <HelperToolTip translationId="addOnTab.applicationLabel" defaultMessage="Application URL">
                                                                                <FormattedMessage
                                                                                    id="helperToolTip.addOnTabApplication"
                                                                                    defaultMessage="Application URL is represented by the 'Apply Now' button. All application URLs should be: https://secure.dominionlending.ca/?app=[YOUR ID]&lang=en"
                                                                                />
                                                                            </HelperToolTip>
                                                                        </label>
                                                                        <input
                                                                            id="broker-application-link"
                                                                            type="text"
                                                                            name="applicationUrl"
                                                                            value={values.applicationUrl}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                this.handleFeatureChange(e, user);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);

                                                                                if (errors.applicationUrl) {
                                                                                    this.handleButtonUrlsErrorChange(e, user);
                                                                                }
                                                                            }}
                                                                            className={errors.applicationUrl && touched.applicationUrl ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.applicationUrl && touched.applicationUrl && <div className="input-feedback">{errors.applicationUrl}</div>}
                                                                    </form>
                                                                );
                                                            }}
                                                        </Formik>
                                                    </AddOn>
                                                )}

                                                <AddOn
                                                    id="awardImageUrl"
                                                    translateId="addOnTab.awardsAddOn"
                                                    translateMessage="Awards"
                                                    status={
                                                        user.type === "broker-user"
                                                            ? template.features.awardImageUrl === ""
                                                                ? "deactive"
                                                                : "active"
                                                            : template.member.awardImageUrl === "" || undefined || null || !template.member.awardImageUrl
                                                            ? "deactive"
                                                            : "active"
                                                    }
                                                >
                                                    <Alert variant="info">
                                                        <p>
                                                            <FormattedMessage
                                                                id="addOnTab.awardsAlert"
                                                                defaultMessage="Please use the link below to choose and download your award badge from your profile on the intranet."
                                                            />
                                                        </p>
                                                        <a
                                                            href={
                                                                "https://secure.dominionintranet.ca/" +
                                                                (language.locale === "fr" ? "bienvenue" : "welcome") +
                                                                "/profile/broker_edit/" +
                                                                (language.locale === "fr" ? "awards" : "rankings") +
                                                                ".php"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="pl-0"
                                                        >
                                                            <b>
                                                                <FormattedMessage id="addOnTab.downloadAwardIcon" defaultMessage="Get your award badge." /> <i className="fas fa-external-link"></i>
                                                            </b>
                                                        </a>
                                                    </Alert>
                                                    <Upload imageType="awardImageUrl" />
                                                </AddOn>

                                                {user.type === "broker-user" ? (
                                                    <AddOn
                                                        id="mortgageToolboxUrl"
                                                        translateId="addOnTab.myMortgageToolBoxAddOn"
                                                        translateMessage="My Mortgage Toolbox"
                                                        status={template.features.mortgageToolboxUrl === undefined || template.features.mortgageToolboxUrl === "" ? "deactive" : "active"}
                                                    >
                                                        <Formik
                                                            initialValues={{ mortgageToolboxUrl: template.features.mortgageToolboxUrl ? template.features.mortgageToolboxUrl : "" }}
                                                            enableReinitialize={this.state.myMortgageToolboxInitialize === true ? true : false}
                                                            validationSchema={Yup.object().shape({
                                                                mortgageToolboxUrl: Yup.string()
                                                                    .matches(
                                                                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                                                        intl.formatMessage({
                                                                            id: "addOnTab.mortgagetoolboxValidation",
                                                                            defaultMessage: "Invalid mortgage toolbox url",
                                                                        })
                                                                    )
                                                                    .notRequired(),
                                                            })}
                                                        >
                                                            {(props) => {
                                                                const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                                                return (
                                                                    <form className="mb-2" onSubmit={handleSubmit}>
                                                                        <label className="mt-2" htmlFor="broker-my-mortgage-toolbox-link">
                                                                            <FormattedMessage id="addOnTab.myMortgateToolBoxLabel" defaultMessage="My Mortgate Tool Box URL" />
                                                                            <HelperToolTip translationId="addOnTab.myMortgateToolBoxLabel" defaultMessage="My Mortgate Tool Box URL">
                                                                                <FormattedMessage
                                                                                    id="helperToolTip.addOnTabMyMortgageToolboxUrl"
                                                                                    defaultMessage="My Mortgage Toolbox URL is represented by the 'Get Pre-qualified' button. All My Mortgage Toolbox URLs should be: https://dlcapp.ca/id/[YOUR ID]?lang=en"
                                                                                />
                                                                            </HelperToolTip>
                                                                        </label>
                                                                        <input
                                                                            id="broker-my-mortgage-toolbox-link"
                                                                            type="text"
                                                                            name="mortgageToolboxUrl"
                                                                            value={values.mortgageToolboxUrl}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                this.handleFeatureChange(e, user);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);

                                                                                if (errors.mortgageToolboxUrl) {
                                                                                    this.handleButtonUrlsErrorChange(e, user);
                                                                                }
                                                                            }}
                                                                            className={errors.mortgageToolboxUrl && touched.mortgageToolboxUrl ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.mortgageToolboxUrl && touched.mortgageToolboxUrl && <div className="input-feedback">{errors.mortgageToolboxUrl}</div>}
                                                                    </form>
                                                                );
                                                            }}
                                                        </Formik>
                                                    </AddOn>
                                                ) : (
                                                    <AddOn
                                                        id="mortgageToolboxUrl"
                                                        translateId="addOnTab.myMortgageToolBoxAddOn"
                                                        translateMessage="My Mortgage Toolbox"
                                                        status={template.member.mortgageToolboxUrl === undefined || template.member.mortgageToolboxUrl === "" ? "deactive" : "active"}
                                                    >
                                                        <Formik
                                                            initialValues={{
                                                                mortgageToolboxUrl: template.member.mortgageToolboxUrl,
                                                            }}
                                                            enableReinitialize={this.state.myMortgageToolboxInitialize === true ? true : false}
                                                            validationSchema={Yup.object().shape({
                                                                mortgageToolboxUrl: Yup.string()
                                                                    .matches(
                                                                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                                                        intl.formatMessage({
                                                                            id: "addOnTab.mortgagetoolboxValidation",
                                                                            defaultMessage: "Invalid mortgage toolbox url",
                                                                        })
                                                                    )
                                                                    .notRequired(),
                                                            })}
                                                        >
                                                            {(props) => {
                                                                const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                                                return (
                                                                    <form className="mb-2" onSubmit={handleSubmit}>
                                                                        <label className="mt-2" htmlFor="broker-my-mortgage-toolbox-link">
                                                                            <FormattedMessage id="addOnTab.myMortgateToolBoxLabel" defaultMessage="My Mortgate Tool Box URL" />
                                                                            <HelperToolTip translationId="addOnTab.myMortgateToolBoxLabel" defaultMessage="My Mortgate Tool Box URL">
                                                                                <FormattedMessage
                                                                                    id="helperToolTip.addOnTabMyMortgageToolboxUrl"
                                                                                    defaultMessage="My Mortgage Toolbox URL is represented by the 'Get Pre-qualified' button. All My Mortgage Toolbox URLs should be: https://dlcapp.ca/id/[YOUR ID]?lang=en"
                                                                                />
                                                                            </HelperToolTip>
                                                                        </label>
                                                                        <input
                                                                            id="broker-my-mortgage-toolbox-link"
                                                                            type="text"
                                                                            name="mortgageToolboxUrl"
                                                                            value={values.mortgageToolboxUrl}
                                                                            onChange={(e) => {
                                                                                handleChange(e);
                                                                                this.handleFeatureChange(e, user);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                handleBlur(e);

                                                                                if (errors.mortgageToolboxUrl) {
                                                                                    this.handleButtonUrlsErrorChange(e, user);
                                                                                }
                                                                            }}
                                                                            className={errors.mortgageToolboxUrl && touched.mortgageToolboxUrl ? "text-input error" : "text-input"}
                                                                        />
                                                                        {errors.mortgageToolboxUrl && touched.mortgageToolboxUrl && <div className="input-feedback">{errors.mortgageToolboxUrl}</div>}
                                                                    </form>
                                                                );
                                                            }}
                                                        </Formik>
                                                    </AddOn>
                                                )}
                                            </Fragment>
                                        )}

                                        <AddOn
                                            id="bannerUrl"
                                            containerId="custom-banner"
                                            translateId="addOnTab.customBannerAddOn"
                                            translateMessage="Custom Banner"
                                            status={template.features.bannerUrl === "" ? "deactive" : "active"}
                                        >
                                            <Alert variant="info">
                                                <p>
                                                    <FormattedMessage
                                                        id="addOnTab.customBannerAlertFileFormatsSize"
                                                        defaultMessage="Recommended banner width is 1300px wide. Accepted file formats are JPG, JPEG, or PNG with an upload limit of 5MB."
                                                    />
                                                </p>
                                                <a
                                                    href={"https://secure.dominionintranet.ca/" + (language.locale === "fr" ? "bienvenue" : "welcome") + "/marketing_signature_banners.php"}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="pl-0"
                                                >
                                                    <b>
                                                        <FormattedMessage id="addOnTab.downloadCustomBannerIcon" defaultMessage="Get your custom banner." /> <i className="fas fa-external-link"></i>
                                                    </b>
                                                </a>
                                            </Alert>

                                            <Formik
                                                initialValues={{ customBannerUrl: template.features.customBannerUrl ? template.features.customBannerUrl : "" }}
                                                enableReinitialize={this.state.customBannerInitialize === true ? true : false}
                                                validationSchema={Yup.object().shape({
                                                    customBannerUrl: Yup.string()
                                                        .matches(
                                                            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                                            intl.formatMessage({
                                                                id: "addOnTab.customBannerValidation",
                                                                defaultMessage: "Invalid custom banner url",
                                                            })
                                                        )
                                                        .notRequired(),
                                                })}
                                            >
                                                {(props) => {
                                                    const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                                    return (
                                                        <form className="mb-3" onSubmit={handleSubmit}>
                                                            <label className="mt-2" htmlFor="custom-banner-link">
                                                                <FormattedMessage id="addOnTab.customBannerLabel" defaultMessage="Custom Banner URL" />
                                                            </label>
                                                            <input
                                                                id="custom-banner-link"
                                                                type="text"
                                                                name="customBannerUrl"
                                                                value={values.customBannerUrl}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    this.handleFeatureChange(e, user);
                                                                }}
                                                                onBlur={(e) => {
                                                                    handleBlur(e);

                                                                    if (errors.customBannerUrl) {
                                                                        this.handleButtonUrlsErrorChange(e, user);
                                                                    }
                                                                }}
                                                                className={errors.customBannerUrl && touched.customBannerUrl ? "text-input error" : "text-input"}
                                                            />
                                                            {errors.customBannerUrl && touched.customBannerUrl && <div className="input-feedback">{errors.customBannerUrl}</div>}
                                                        </form>
                                                    );
                                                }}
                                            </Formik>

                                            <UploadBanner imageType="bannerUrl" />
                                        </AddOn>

                                        {(user.type === "franchise-user" || user.type === "broker-user") && (
                                            <AddOn
                                                id="confidentialityWarning"
                                                translateId="addOnTab.confidentialityAddOn"
                                                translateMessage="Confidentiality Warning"
                                                status={template.features.confidentialityWarning === "" ? "deactive" : "active"}
                                            >
                                                <Formik
                                                    initialValues={{
                                                        confidentialityWarning: template.features.confidentialityWarning ? template.features.confidentialityWarning : "",
                                                    }}
                                                    enableReinitialize={this.state.confidentialityWarningInitialize === true ? true : false}
                                                    validationSchema={Yup.object().shape({
                                                        confidentialityWarning: Yup.string()
                                                            .max(
                                                                539,
                                                                intl.formatMessage({
                                                                    id: "addOnTab.confidentialityWarningValidation",
                                                                    defaultMessage: " Your confidentiality message is too long.",
                                                                })
                                                            )
                                                            .notRequired(),
                                                    })}
                                                >
                                                    {(props) => {
                                                        const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                                        return (
                                                            <form className="mb-2" onSubmit={handleSubmit}>
                                                                <label className="mt-2" htmlFor="confidentiality-warning">
                                                                    <FormattedMessage id="addOnTab.confidentialityLabel" defaultMessage="Confidentiality Message" />
                                                                </label>
                                                                <textarea
                                                                    id="confidentiality-warning"
                                                                    name="confidentialityWarning"
                                                                    rows="4"
                                                                    maxLength="540"
                                                                    value={values.confidentialityWarning}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        this.handleFeatureChange(e, user);
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        handleBlur(e);

                                                                        if (errors.confidentialityWarning) {
                                                                            this.handleErrorChange(e);
                                                                        }
                                                                    }}
                                                                    className={errors.confidentialityWarning && touched.confidentialityWarning ? "text-input error" : "text-input"}
                                                                />
                                                                {errors.confidentialityWarning && touched.confidentialityWarning && (
                                                                    <div className="input-feedback">{errors.confidentialityWarning}</div>
                                                                )}
                                                            </form>
                                                        );
                                                    }}
                                                </Formik>
                                            </AddOn>
                                        )}
                                    </Col>
                                </Row>
                            </Fragment>
                        )}
                    </LanguageContext.Consumer>
                )}
            </UserContext.Consumer>
        );
    }
}

export default injectIntl(AddOnTab);
